
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "../node_modules/@coreui/coreui/scss/coreui";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$uam-primary: mat.define-palette(mat.$indigo-palette);

// The warn palette is optional (defaults to red).
$uam-warn: mat.define-palette(mat.$red-palette);

$custom-palette-3: (
  50: #fbf5e0,
   100: #f5e6b1,
   200: #efd67f,
   300: #e8c74e,
   400: #e5ba2b,
   500: #e1af15,
   600: #e1a30e,
   700: #e0920a,
   800: #df8206,
   900: #dc6603,
    A100: #e1a30e,
    A200: #e0920a,
    A400: #df8206,
    A700: #dc6603,
  contrast: (
    50: #e8ebfb,
    100: #c4cdf6,
    200: #9cadf0,
    300: #708ceb,
    400: #4a71e6,
    500:#0a57e0,
    600: #004fd5,
    700: #0044c8,
    800: #003abd,
    900: #0025ab,
     A100: #8c9eff,
     A200: #536dfe,
     A400: #3d5afe,
     A700: #304ffe,
  )
);


// $uam-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$uam-accent: mat.define-palette($custom-palette-3, A200, A100, A400);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$uam-theme: mat.define-light-theme((
  color: (
    primary: $uam-primary,
    accent: $uam-accent,
    warn: $uam-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($uam-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
